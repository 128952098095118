<template>
  <div class="item">
     <TitleHeader msg="授信回款"></TitleHeader>
     <el-row class="content">
        <el-col ><span>待回款金额：</span><span class="num">{{nums}}</span></el-col>
        <el-col class="wraper">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">

                <el-form-item label="回款金额" prop="num">
                    <el-input v-model="ruleForm.num" clearable></el-input>
                </el-form-item>
                <el-col :span="24">支付方式</el-col>
                <el-form-item  prop="resource">
                    <el-radio-group v-model="ruleForm.resource" size="medium">
                         <el-col :span="8" v-for="(item,index) in imgArr" :key="index"><em class="el-icon-check"></em><img :src="item.img" :alt="item.alt"></el-col>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                    <el-button @click="goBack">返回</el-button>
                </el-form-item>
            </el-form>
        </el-col>
     </el-row>
  </div>
</template>

<script>
const TitleHeader = ()=>import("components/common/TitleHeader.vue");
  export default {
    name:"CreditRecharge",
    data(){
        return{
             ruleForm: {
                 num:"",
                 resource: ''
             },
             rules: {
                    num: [
                        { required: true, message: '请输入要充值的金额', trigger: 'blur' },
                    ],
                    resource: [
                        { required: true, message: '请选择支付方式', trigger: 'change' }
                    ],
                },
            //要充值的金额
            input:"",
            num:"66433666.49",
            imgArr:[
                {
                    img:'http://image.demo.b2b2c.shopxx.net/6.0/aa348738-a710-4330-915d-a2d2475681e6.png',
                    alt:"支付宝"
                },
                {
                    img:'http://image.demo.b2b2c.shopxx.net/6.0/16d9ceaf-ada6-480d-95c3-6244eac4b0ee.png',
                     alt:"微信支付"
                }
            ]
        }
    },
    methods:{
     submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //   alert('sucess')
          } else {
            return false;
          }
        });
      },
      goBack() {
        this.$router.back()
      },
      jumpYpt(){
          this.$router.push({name:"Ypt"})
      }
  },
   created(){
     
     
    },
    computed:{
       nums(){
           return "￥"+this.num+"元"
       } 
    },
    components:{
       TitleHeader
    }
  }
</script>
<style lang="less" scoped>
@import '~style/index.less';
.item {
    margin-top: 30px;
    .content {
            border-top: 1px solid #eee;
            padding-top: 20px;
            .num {
                    font-weight: 600;
                    color: #f04134;
                    padding-right: 30px;
                }
            .wraper {
                display: flex;
                align-items: center;
                
            }
    }
    .el-form-item__label {
        text-align: left;
    }
    
}
 
</style>
